<template>
<div class="row">
    <!-- <div class="col">
        <div class="btn-group dropup">
            <button type="button" class="btn btn-secondary dropdown-toggle botaoDropUp" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">50 itens por página</button>
        </div>
    </div> -->
    <div class="col">
        <ul class="pagination pagination-sm no-margin pull-right ">
        <li :class="{'cc-disabled': paginaAtual == 1}" @click="funcao(1, true)">
            <a href="#">«</a>
        </li>
        <li :class="{'cc-disabled': paginaAtual == 1}" @click="funcao(paginaAtual - 1, true)">
                        <a href="#"><</a>

        </li>
        <li v-if="!proximasPaginas(1)">
            <span>&hellip;</span>
        </li>
        <li v-for="n in totalPaginas" :key="n" v-if="proximasPaginas(n)" :class="{'active': n == paginaAtual}" @click="funcao(n, true)">
            <a href="#">{{ n }}</a>
        </li>
        <li v-if="!proximasPaginas(totalPaginas)">
            <span>&hellip;</span>
        </li>
        <li :class="{'cc-disabled': paginaAtual == totalPaginas}" @click="funcao(paginaAtual + 1, true)">
            <a href="#">></a>
        </li>
        <li :class="{'cc-disabled': paginaAtual == totalPaginas}" @click="funcao(totalPaginas, true)">
            <a href="#">»</a>
        </li>
    </ul>
    </div>
</div>
</template>

<script>
export default {
    props: ['paginaAtual', 'totalPaginas', 'funcao'],
    methods: {
        proximasPaginas(n) {
            let p = this.paginaAtual
            let t = this.totalPaginas

            if (p == 1) return [p, p + 1, p + 2, p + 3, p + 4].includes(n)
            else if (p == 2) return [p - 1, p, p + 1, p + 2, p + 3].includes(n)
            else if (p == t) return [p, p - 1, p - 2, p - 3, p - 4].includes(n)
            else if (p == t - 1) return [p + 1, p, p - 1, p - 2, p - 3].includes(n)
            else return [p - 2, p - 1, p, p + 1, p + 2].includes(n)
        }
    }
}
</script>
<style lang="scss" scoped>
a{
   padding: 10px 15px 10px 15px !important;
   border-radius: 2px !important
   
}
.pagination > .active > a, .pagination > .active > a:focus, .pagination > .active > a:hover, .pagination > .active > span, .pagination > .active > span:focus, .pagination > .active > span:hover {
    background-color: #ff7110 !important;
    margin: 5px !important
   
}
.pagination > li > a {
     background-color: #fbfbfb;
      margin: 5px !important
}
.pagination{
    text-align: right !important
}
.botaoDropUp{
  border-radius: 4px !important;
  border: solid 1px #14abbd !important;
  background-color: #ffffff !important;
   opacity: 0.5 !important;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 13px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.54 !important;
  letter-spacing: normal !important;
  text-align: center !important;
  color: #4d4f5c !important;
}
</style>
